import React, { useEffect } from "react";
import R from "./router/Routes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import AnimatedCursor from "react-animated-cursor";

const App = () => {
  // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
  //   document.body.classList.remove("light");
  //   console.log('dark mode on');
  // }
  // else {
  //   document.body.classList.add("light");
  //   console.log('dark mode off');
  // }
  window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', event => {
    const newColorScheme = event.matches ? "dark" : "light";
    console.log('system color scheme changed');
    if (newColorScheme === "light") {
      document.body.classList.add("light");
    }
    else {
      document.body.classList.remove("light");
      console.log("dark mode")
    }
  });
  // this for animation
  useEffect(() => {
    AOS.init();
  }, []);
  return (
    <>
      <AnimatedCursor
        innerSize={8}
        outerSize={44}
        color="255, 160, 1"
        outerAlpha={0.3}
        innerScale={0.7}
        outerScale={1.2}
      />
      
      <ScrollToTop />
      <R />
    </>
  );
};

export default App;
